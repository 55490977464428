import { AccessForbiddenException, NotAuthorizedException, NotFoundException } from "@utils/type/exception";
import type { AxiosError } from "axios";
import axios, { type AxiosInstance, type AxiosRequestConfig, type AxiosResponse } from "axios";
import qs from "qs";
import { HEADER_LOCKER_ID, HEADER_LOCKER_LEASE, HEADER_LOCKER_LOCKED, HEADER_LOCKER_TYPE, lockerEventsEmitter, type LockerLeaseReceivedEvent, type LockerLockedEvent } from "@utils/type/locker";
import { assert, assertIsDefined } from "@utils/assertion";
import { getCsrfToken } from "@utils/csrfUtils";

type ResponseHandler<T = unknown> = {
  handle: (response: AxiosResponse<T>) => void;
};

class LockerResponseHandler implements ResponseHandler {
  handle(response: AxiosResponse): void {
    const lockerType = response.headers[HEADER_LOCKER_TYPE];
    const lockerId = response.headers[HEADER_LOCKER_ID];
    const lockerLease = HEADER_LOCKER_LEASE in response.headers ? response.headers[HEADER_LOCKER_LEASE] : undefined;

    if (lockerLease !== undefined) {
      assertIsDefined(lockerType);
      assertIsDefined(lockerId);
      const event: LockerLeaseReceivedEvent = {
        lockerKey: { lockerType: lockerType, lockerId: lockerId },
        lockerLease: lockerLease,
      };
      lockerEventsEmitter.emit("lockerLeaseReceivedEvent", event);
    } else if (HEADER_LOCKER_LOCKED in response.headers) {
      assertIsDefined(lockerType);
      assertIsDefined(lockerId);
      const event: LockerLockedEvent = {
        lockerKey: { lockerType: lockerType, lockerId: lockerId },
      };
      lockerEventsEmitter.emit("lockerLockedEvent", event);
    }
  }
}

let axiosInstance: AxiosInstance | null = null;
/** Creating the instance for axios */
export const getHttpClient = (): AxiosInstance => {
  if (axiosInstance !== null) {
    return axiosInstance;
  }
  /** Default config for axios instance */
  const config: AxiosRequestConfig = {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  };
  const httpClient = axios.create(config);
  httpClient.interceptors.request.use(async function (config) {
    if (
      config.method !== undefined &&
      ["POST", "PUT", "DELETE", "PATCH"].includes(config.method.toUpperCase()) && // Only for relevant HTTP verbs
      new URL(axios.getUri(config), window.location.href).origin === window.location.origin // and only for same-origin requests (the token must no be leaked)
    ) {
      config.headers.set("X-XSRF-Token", await getCsrfToken());
    }
    return config;
  });

  httpClient.interceptors.response.use(
    (response: AxiosResponse) => {
      new LockerResponseHandler().handle(response);
      return response;
    },
    (error: AxiosError) => {
      if (error.response != null && error.response.status === 401) {
        throw new NotAuthorizedException(error.config?.method === "get");
      } else if (error.response != null && error.response.status === 403) {
        throw new AccessForbiddenException(error);
      } else if (error.response != null && error.response.status === 404) {
        throw new NotFoundException();
      }
      return Promise.reject(error);
    },
  );
  axiosInstance = httpClient;
  return httpClient;
};

async function getGaApiCallConfig(): Promise<{
  baseUrl: string | null;
  csrfToken: string;
  cookies: string[];
}> {
  if (typeof process !== "object") {
    //running in browser
    return {
      baseUrl: null,
      csrfToken: await getCsrfToken(),
      cookies: [],
    };
  }

  const baseUrl = process.env.GA_API_BASE_URL;
  assert(baseUrl !== undefined);
  assert(typeof baseUrl === "string");

  let token = await getCsrfToken();
  let cookies: string[] = [];
  if (token === "") {
    const response = await fetch(`${baseUrl}/session-keepalive`, {
      headers: {
        Accept: "text/plain",
      },
      method: "GET",
      body: null,
    });
    cookies = response.headers.getSetCookie();
    assert(response.status === 200);
    token = await response.text();
  }

  return {
    baseUrl: baseUrl,
    csrfToken: token,
    cookies: cookies,
  };
}

type GaApiCallProps = {
  path: string;
  method: "GET" | "PUT" | "POST";
  requestBody: string | null;
};

export async function gaApiCall(props: GaApiCallProps): Promise<{ kind: "success"; text: string } | { kind: "error"; message: string }> {
  const gaApiCallConfig = await getGaApiCallConfig();
  const url = gaApiCallConfig.baseUrl == null ? props.path : `${gaApiCallConfig.baseUrl}${props.path}`;
  console.log(gaApiCallConfig);
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-XSRF-TOKEN": gaApiCallConfig.csrfToken,
      Cookie: (document.cookie === "" ? "" : document.cookie + "; ") + gaApiCallConfig.cookies.map((s) => s.split(";")[0]).join("; "),
    },
    credentials: "omit",
    method: props.method,
    body: props.requestBody == null ? undefined : props.requestBody,
  });
  const text = await response.text();
  if (!response.ok) {
    const message = `${url} failed: status=${response.status} body=${text.length <= 50 ? text : text.substring(0, 50) + "..."}`;
    console.warn(message);
    return { kind: "error", message: message };
  }
  return { kind: "success", text: text };
}
